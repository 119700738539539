<template>
  <div>
    <div class="container">
      <div class="header-container">
        <header>
          <van-nav-bar
              :title="title"
              left-text="返回"
              right-text=""
              left-arrow
              @click-left="onBack"
          />
        </header>
      </div>
      <van-form @submit="onSubmit">
        <div class="section-box" style="padding: 5px">
          <van-field
              readonly
              clickable
              name="reportAreaId"
              :value="reportFrom.reportAreaName"
              :label="title+'区域'"
              colon
              required
              :placeholder="'选择'+title+'区域'"
              @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="areaNmaes"
                @confirm="onAreaConfirm"
                @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
              v-model="reportFrom.informer"
              name="informer"
              label="您的称呼"
              placeholder="请填写您的称呼"
              border
              colon
              required
              clearable
              :rules="[{ required: true, message: '' }]"
          />
          <van-field
              v-model="reportFrom.firstPhoneNum"
              name="firstPhoneNum"
              label="手机号码"
              type="number"
              placeholder="请填写手机号码"
              border
              colon
              required
              clearable
              :rules="[{ required: true, message: '' }]"
          />
          <van-field
              v-model="reportFrom.secondPhoneNum"
              name="secondPhoneNum"
              label="联系电话"
              placeholder="请填写联系电话"
              border
              colon
              clearable
          />
          <van-field
              v-model="reportFrom.address"
              name="address"
              :label="title+'地址'"
              :placeholder="'请填写'+title+'地址'"
              border
              colon
              rows="2"
              autosize
              type="textarea"
              required
              :rules="[{ required: true, message: '' }]"
          />
          <van-field
              v-model="reportFrom.situation"
              name="situation"
              label="具体情况"
              placeholder="请填写具体情况"
              border
              colon
              rows="5"
              autosize
              type="textarea"
              required
              :rules="[{ required: true, message: '' }]"
          />
          <van-field name="attachmentPath" label-width="0">
            <template #input>
              <van-row>
                <van-col span="24">
                  现场照片
                </van-col>
                <van-col span="24">
                  <UploadPic max-count="5" :upload-size="1024 * 1024 * 20" tag="1" @uploadSate="uploadSate"></UploadPic>
                </van-col>
              </van-row>
            </template>
          </van-field>
        </div>
        <div style="margin: 16px;">
          <van-button type="info" round block>提交</van-button>
        </div>
      </van-form>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {initReportArea, saveData} from "../../api/report";
import UploadPic from "../../components/UploadPic";
import {Dialog} from "vant";
export default {
  name: "reportIndex",
  components: {Loading, UploadPic},
  data(){
    return{
      isShow : false,
      title:this.$route.query.title,
      bzType: this.$route.query.bzType,
      lodingTxt: '加载中...',
      showPicker: false,
      areas:[],
      areaNmaes:[],
      reportFrom:{
        reportAreaId:'',
        reportAreaName:'',
        informer:'',
        firstPhoneNum:'',
        secondPhoneNum:'',
        address:'',
        situation:'',
        attachmentPath:[],
        bzType:this.$route.query.bzType,
      }
    }
  },
  created() {
    document.title = this.$route.query.title
    if (this.bzType == 0) {
      Dialog({
        title: '尊敬的用户',
        message: '本业务受理水管、阀门、消防栓等供水设施漏水报修业务。',
      })
    }
    if (this.bzType == 1){
      Dialog({
        title: '尊敬的用户',
        message: '本业务受理供水、抄表、收费、水质、施工、表具等相关投诉建议及违章用水举报业务。',
      })
    }
    this.getReportArea()
  },
  methods:{
    /**
     * 提交
     */
    onSubmit(){
      const that = this
      console.log(that.reportFrom)
      if (!that.reportFrom.reportAreaId || that.reportFrom.reportAreaId==''){
        this.$toast('请选择'+that.title+'区域')
        return
      }
      that.lodingTxt = '正在保存...'
      that.isShow = true
      saveData(that.reportFrom).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          Dialog.alert({
            title: '温馨提示',
            message: response.message,
          }).then(() => {
            window.location.reload()
          });
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })

    },
    onBack : function (){
      this.$router.go(-1);
    },
    getReportArea(){
      const that = this
      that.isShow = true
      that.lodingTxt = '查询中...'
      initReportArea().then(response => {
        console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.areas = response.data
          that.areaNmaes = []
          that.areas.forEach(item =>{
            that.areaNmaes.push(item.reportArea)
          })
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.code = ''
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onAreaConfirm(e) {
      console.log(e)
      this.showPicker = false
      //获取id
      let i = 0
      this.areaNmaes.forEach(item => {
        if (item == e){
          const data = this.areas[i]
          console.log(data)
          this.reportFrom.reportAreaName = item
          this.reportFrom.reportAreaId = data.id
          return
        }
        i++
      })
    },/**
     * 上传状态回调
     */
    uploadSate(obj){
      const uploadDates = obj.uploadDates
      this.reportFrom.attachmentPath = []
      uploadDates.forEach(item => {
        this.reportFrom.attachmentPath.push(item.url)
      });
    }
  }
}
</script>

<style scoped>

</style>