import request from '../utils/request'
//import qs from 'qs'
export function initReportArea(){
    return request({
        url: 'report/initReportArea.do',
        method: 'post',
        //data: qs.stringify({accountNo: accountNo,code:code})
    })
}
/**
 * 保存居民过户
 * @param param
 * @returns {*}
 */
export function saveData(param){
    param.attachmentPath = param.attachmentPath.toString()
    param['postType'] = 'application/json'
    return request({
        url: 'report/save.do',
        method: 'post',
        data: param
    })
}